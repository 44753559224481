import React from 'react'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import { withPrefix, attribute } from '../utils'
import '../sass/main.scss'

let toggleMenuFunc = () => {}

export const toggleMenu = () => {
    toggleMenuFunc()
}

export default class Body extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuOpened: false,
        }
    }

    componentDidMount() {
        toggleMenuFunc = () => {
            this.setState({
                menuOpened: !this.state.menuOpened,
            })
        }
    }

    render() {
        const post = _.get(this.props, 'data.contentfulLanding')
        let font = _.get(this.props, 'pageContext.site.siteMetadata.base_font', null) || 'nunito-sans'
        return (
            <React.Fragment>
                <Helmet>
                    <title>{_.get(this.props, 'data.site.siteMetadata.title', null)}</title>
                    <meta charSet="utf-8" />
                    <meta name="google" content="notranslate" />
                    <meta name="theme-color" content="#00FF00" />

                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta name="application-name" content="NANO EH" />
                    <meta name="apple-mobile-web-app-title" content="NANO EH" />
                    <meta name="apple-mobile-web-app-status-bar-style" content="green" />

                    <meta name="description" content="" />
                    <meta name="keywords" content="" />
                    <meta name="HandheldFriendly" content="True" />
                    <meta name="MobileOptimized" content="320" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                    <meta http-equiv="cleartype" content="on" />

                    {font !== 'system-sans' && <link rel="preconnect" href="https://fonts.gstatic.com" />}
                    {font === 'nunito-sans' ? (
                        <link
                            href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
                            rel="stylesheet"
                        />
                    ) : (
                        font === 'fira-sans' && (
                            <link
                                href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap"
                                rel="stylesheet"
                            />
                        )
                    )}
                    {_.get(this.props, 'pageContext.site.siteMetadata.favicon', null) && (
                        <link rel="icon" href={withPrefix(_.get(this.props, 'pageContext.site.siteMetadata.favicon', null))} />
                    )}
                    <body className={'palette-blue' + ' font-nunito-sans' + (this.state.menuOpened ? ' menu--opened' : '')} />
                </Helmet>
                <div id="page" className="site">
                    <main id="content" className={this.props.siteClass}>
                        {this.props.children}
                    </main>
                </div>
            </React.Fragment>
        )
    }
}
