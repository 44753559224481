import BackgroundImage from 'gatsby-background-image'
import _ from 'lodash'
import React from 'react'
import { FaEdit } from 'react-icons/fa'
import { Link } from 'gatsby-plugin-intl'

const SectionNewsletter = ({ theme, block, isAdmin, section_id }) => {
    const backgroundFluidImageStack = [block.heroImage.fluid].reverse()

    switch (theme) {
        default:
        case 'main':
            return (
                <section
                    id={section_id}
                    className="block newsletter-block bg-accent outer"
                    style={{
                        overflow: 'hidden',
                    }}
                    fluid={backgroundFluidImageStack}
                >
                    {isAdmin && (
                        <Link
                            style={{ position: 'absolute', top: 16, right: 16 }}
                            to={`https://app.contentful.com/spaces/dmb5ihmit9yv/entries/${block.contentful_id}`}
                        >
                            <FaEdit /> Edit
                        </Link>
                    )}
                    <div className="inner">
                        <div className="cell">
                            {block.title && block.title.substring(0, 2) !== "__" &&
                                <h2 className="block-title" style={{ marginBottom: 0 }}>
                                    {block.title}
                                </h2>
                            }
                        </div>
                        <div className="cell" style={{ marginBottom: 40 }}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: block.body.childMarkdownRemark.html,
                                }}
                            />
                        </div>
                        <form>
                            <div
                                style={{
                                    float: 'left',
                                    width: '75%',
                                    marginRight: 16,
                                }}
                            >
                                <input className="primary-input" type="text" name="name" placeholder={block.placeholder} />
                            </div>
                            <input type="submit" class="tertiary-button" value={block.subscribe} style={{ width: '20%', height: 47 }} />
                        </form>
                    </div>
                </section>
            )
        case 'alt':
            return (
                <section id={_.get(this.props, 'section_id', null)} className="block hero-block bg-accent outer">
                    {isAdmin && (
                        <Link
                            style={{ position: 'absolute', top: 16, right: 16 }}
                            to={`https://app.contentful.com/spaces/dmb5ihmit9yv/entries/${block.contentful_id}`}
                        >
                            <FaEdit /> Edit
                        </Link>
                    )}
                    <div className="inner">
                        <div className="cell">
                            <h2 className="block-title">{block.title}</h2>
                        </div>
                        <div className="cell">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: block.body.childMarkdownRemark.html,
                                }}
                            />
                        </div>
                        <form>
                            <div
                                style={{
                                    float: 'left',
                                    width: '75%',
                                    marginRight: 16,
                                }}
                            >
                                <input className="primary-input" type="text" name="name" placeholder={block.placeholder} />
                            </div>
                            <input type="submit" class="tertiary-button" value={block.subscribe} style={{ width: '20%', height: 47 }} />
                        </form>
                    </div>
                </section>
            )
    }
}

export default SectionNewsletter
