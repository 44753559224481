import { Link } from 'gatsby-plugin-intl'
import _ from 'lodash'
import { default as React } from 'react'
import { FaEdit } from 'react-icons/fa'

const SectionFooter = ({ theme, block, isAdmin, section_id }) => {
    return (
        <div id={section_id} className="block hero-block bg-accent-simple outer">
            {isAdmin && (
                <div style={{ textAlign: 'end', color: 'white' }}>
                    <Link style={{ color: 'white' }} to={`https://app.contentful.com/spaces/dmb5ihmit9yv/entries/${block.contentful_id}`}>
                        <FaEdit /> Edit
                    </Link>
                </div>
            )}
            <div className="grid">
                <div className="cell">
                    <div
                        style={{ color: 'white', width: '100%', textAlign: 'center', fontWeight: 'bold' }}
                        dangerouslySetInnerHTML={{
                            __html: block.footerText.childMarkdownRemark.html,
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
export default SectionFooter
