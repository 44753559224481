import _ from 'lodash'
import React from 'react'

import Img from 'gatsby-image'
import Menu from 'react-burger-menu/lib/menus/slide'
import { FaBook, FaCalendar, FaHome, FaNewspaper, FaPersonBooth } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { useMediaQuery } from 'react-responsive'
import { classNames, Link, withPrefix } from '../utils'
import Action from './Action'
import { toggleMenu } from './AzLayout'

const icons = {
    home: FaHome,
    project: FaBook,
    person: FaPersonBooth,
    calendar: FaCalendar,
    newspaper: FaNewspaper,
}

const searchIndices = [{ name: `Pages`, title: `Pages` }]

const Header = (props) => {
    // const [actionList, setActionList] = React.useState(_.get(props, 'links', null))
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const { theme } = props

    // React.useEffect(() => {
    //     setActionList([
    //         ..._.get(props, 'links', null),
    //         {
    //             title: 'Private area',
    //             node_locale: 'en-US',
    //             shortName: 'Private area',
    //             slug: 'private-area',
    //             icon: {
    //                 iconName: 'project',
    //             },
    //             style: 'primary',
    //             label: 'Private area',
    //             url: '/',
    //         },
    //     ])
    // }, [])

    const actionList = _.get(props, 'links', null)
    let subActionList = null
    const subMenu = _.get(props.subMenu, 'links', null)
    // if (subMenu) {
    //     subActionList = subMenu
    // }

    if (theme === 'alt') {
        return [
            <header id="masthead" className="site-header outer">
                <div className="inner">
                    <div className="site-header-inside">
                        <div className="site-branding">
                            {_.get(props, 'logo', null) && (
                                <p className="site-logo">
                                    <Link to={withPrefix('/')}>
                                        <Img
                                            style={{ width: 288, height: 63, objectFit: 'contain', objectPosition: 'center center' }}
                                            // alt={_.get(props, 'data.site.siteMetadata.header.logo_img_alt', null)}
                                            fluid={props.logo.fluid}
                                        />
                                    </Link>
                                </p>
                            )}
                        </div>
                        <nav id="main-navigation" className="site-navigation" aria-label="Main Navigation">
                            <div className="site-nav-inside">
                                <button id="menu-close" className="menu-toggle">
                                    <span className="screen-reader-text">Open Menu</span>
                                    <span className="icon-close" aria-hidden="true" />
                                </button>
                                <ul className="menu">
                                    {_.map(_.get(props, 'links', null), (action, action_idx) => {
                                        let page_url = _.trim(_.get(props, 'data.url', null), '/')
                                        let action_url = _.trim(_.get(action, 'slug', null), '/')
                                        let action_style = _.get(action, 'style', null) || 'link'

                                        return (
                                            <li
                                                key={`header_action_${action_idx}`}
                                                className={classNames('menu-item', {
                                                    'current-menu-item': page_url === action_url,
                                                    'menu-button': action_style !== 'link',
                                                })}
                                            >
                                                <Action
                                                    {...props}
                                                    action={action}
                                                    uppercase={true}
                                                    site_url={props.data.site.siteMetadata.site_url}
                                                />
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </nav>
                        <div style={{ margin: 'auto', alignItems: 'center' }} className="grid">
                            {/* <Search indices={searchIndices} /> */}
                            {/* <Language /> */}
                        </div>
                    </div>
                </div>
            </header>,

            <Menu right styles={{ bmBurgerButton: { display: !isTabletOrMobile ? 'none' : 'inherit' } }}>
                <IconContext.Provider value={{ color: 'black', size: '48px' }}>
                    <nav class="bm-item-list" style={{ height: '100%' }}>
                        {_.map(_.get(props, 'links', null), (action, action_idx) => {
                            let page_url = _.trim(_.get(props, 'data.url', null), '/')
                            let action_url = _.trim(_.get(action, 'slug', null), '/')
                            let action_style = _.get(action, 'style', null) || 'link'

                            return (
                                <div
                                    key={action_idx}
                                    className={classNames('menu-item', {
                                        'current-menu-item': page_url === action_url,
                                        'menu-button': action_style !== 'link',
                                        marginBottom: 8,
                                    })}
                                >
                                    <Action
                                        icon={action && action.icon ? icons[action.icon.iconName] : undefined}
                                        {...props}
                                        action={action}
                                        uppercase={true}
                                        site_url={props.data.site.siteMetadata.site_url}
                                    />
                                    <hr />
                                </div>
                            )
                        })}
                    </nav>
                </IconContext.Provider>
            </Menu>,
        ]
    } else {
        return (
            <header id="masthead" className="site-header outer" style={{ paddingTop: 16 }}>
                <div className="inner">
                    <div className="site-header-inside">
                        <div className="site-branding">
                            {_.get(props, 'logo', null) && (
                                <p className="site-logo">
                                    <Link to={withPrefix('/')}>
                                        <Img
                                            style={{ width: 288, height: 74, objectFit: 'fit', objectPosition: 'center center' }}
                                            // alt={_.get(props, 'data.site.siteMetadata.header.logo_img_alt', null)}
                                            fluid={props.logo.fluid}
                                        />
                                    </Link>
                                </p>
                            )}
                            {/* {_.get(props, 'pageContext.frontmatter.template', null) === 'landing' ||
                            _.get(props, 'pageContext.frontmatter.template', null) === 'blog' ? ( */}
                            <h1
                                className={classNames('site-title', {
                                    'screen-reader-text': _.get(props, 'props.data.site.siteMetadata.header.logo_img', null),
                                })}
                            >
                                <Link to={withPrefix('/')}>{_.get(props, 'props.data.site.siteMetadata.title', null)}</Link>
                            </h1>
                            {/* ) : ( */}
                            {/* <p
                                    className={classNames('site-title', {
                                        'screen-reader-text': _.get(props, 'props.data.site.siteMetadata.header.logo_img', null),
                                    })}
                                >
                                    <Link to={withPrefix('/')}>{_.get(props, 'props.data.site.siteMetadata.title', null)}</Link>
                                </p> */}
                            {/* )} */}
                        </div>
                        <React.Fragment>
                            <nav id="main-navigation" className="site-navigation" aria-label="Main Navigation">
                                <div className="site-nav-inside">
                                    <button
                                        id="menu-close"
                                        className="menu-toggle"
                                        onClick={() => {
                                            toggleMenu()
                                        }}
                                    >
                                        <span className="screen-reader-text">Open Menu</span>
                                        <span className="icon-close" aria-hidden="true" />
                                    </button>
                                    <ul className="menu">
                                        {_.map(actionList, (action, action_idx) => {
                                            let page_url = _.trim(_.get(props, 'data.url', null), '/')
                                            let action_url = _.trim(_.get(action, 'slug', null), '/')
                                            let action_style = _.get(action, 'style', null) || 'link'
                                            return (
                                                <li
                                                    key={action_idx}
                                                    className={classNames('menu-item', {
                                                        'current-menu-item': page_url === action_url,
                                                        'menu-button': action_style !== 'link',
                                                    })}
                                                >
                                                    <Action
                                                        icon={action && action.icon ? icons[action.icon.iconName] : undefined}
                                                        {...props}
                                                        action={action}
                                                        uppercase={true}
                                                        site_url={props.data.site.siteMetadata.site_url}
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </nav>
                            <button
                                id="menu-open"
                                className="menu-toggle"
                                onClick={() => {
                                    toggleMenu()
                                }}
                            >
                                <span className="screen-reader-text">Close Menu</span>
                                <span className="icon-menu" aria-hidden="true" />
                            </button>
                        </React.Fragment>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header
