import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'
import { usePalette } from 'react-palette'
import LinkOrAction from './LinkOrAction'

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}

const SectionHero = ({ theme, block, isAdmin, section_id }) => {
    const backgroundFluidImageStack = block.heroImage.fluid
        ? [block.heroImage.fluid, `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))`].reverse()
        : ''
    const { data, loading, error } = usePalette(block.heroImage.fluid ? block.heroImage.fluid.src : '')

    let textColor = '#FFFFFF'

    if (!block.heroImage.fluid) {
        return (
            <div class="header-container">
                <video preload="true" autoplay="autoplay" loop="loop" volume="0" muted="true">
                    <source src={block.heroImage.file.url} type="video/mp4" />
                </video>
                <div class="text-container">
                    <div class="text-wrapper">
                        <h3>{block.title}</h3>
                        {block.body && (
                            <div className="block-copy">
                                <div
                                    style={{ color: textColor }}
                                    dangerouslySetInnerHTML={{
                                        __html: block.body.childMarkdownRemark.html,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <BackgroundImage
            id={section_id}
            className="block hero-block bg-accent outer"
            style={{
                overflow: 'hidden',
            }}
            fluid={block.title || (block.body && block.body.html) ? backgroundFluidImageStack : block.heroImage.fluid}
        >
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16, color: textColor }}
                    to={`https://app.contentful.com/spaces/dmb5ihmit9yv/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}

            <div
            // style={{
            //     marginTop: '3.16667em',
            // }}
            >
                <div className="cell block-content">
                    <h2 className="block-title underline" style={{ color: textColor }}>
                        {block.title}
                    </h2>
                    {block.body && (
                        <div className="block-copy">
                            <div
                                style={{ color: textColor }}
                                dangerouslySetInnerHTML={{
                                    __html: block.body.childMarkdownRemark.html,
                                }}
                            />
                        </div>
                    )}

                    {block.callToActions && block.callToActions.length > 0 && (
                        <div className="block-buttons">
                            {block.callToActions.map(({ title, subtitle }, index) => (
                                <LinkOrAction key={`linkoraction_${index}`} title={title} subtitle={subtitle} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </BackgroundImage>
    )
}

export default SectionHero
