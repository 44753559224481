import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit, FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaDiscord } from 'react-icons/fa'

const searchIndices = [{ name: `Pages`, title: `Pages` }]

const SectionRecentPosts = ({ block, isAdmin, section_id }) => {
    return (
        <section className="outer colophon">
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/dmb5ihmit9yv/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}
            <div className="inner">
                <div className="cell">
                    <h6 className="primary-text" style={{ paddingTop: 16, marginBottom: 0, textAlign: 'center', textTransform: 'uppercase' }}>
                        {block.title}
                    </h6>
                    {/* <hr className="hr-primary" style={{ margin: 'auto', height: 4, width: 300, marginBottom: 40 }} /> */}
                </div>
                <div className="site-social-inside">
                    <div style={{ margin: 'auto', width: 300, textAlign: 'center', fontSize: 48 }} className="grid">
                        <a href={block.facebook} style={{ marginRight: 8, marginLeft: 'auto' }}>
                            <FaFacebook />
                        </a>
                        <a href={block.linkedin} style={{ marginRight: 8, marginLeft: 8 }}>
                            <FaDiscord />
                        </a>
                        <a href={block.twitter} style={{ marginRight: 8, marginLeft: 8 }}>
                            <FaTwitter />
                        </a>
                        <a href={block.instagram} style={{ marginLeft: 8, marginRight: 'auto' }}>
                            <FaInstagram />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionRecentPosts
